import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import { CurveCarveTop, CurveCarveBottom } from "../components/Curves/Curve"

import { FaArrowCircleDown } from "react-icons/fa"

const GuideDownload = ({ data }) => {
  const { contentfulGuide } = data

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <SEO title={`Download the ${contentfulGuide.title}`} />

      <Container className="text-center">
        <Row>
          <Col>
            <h1>Thank you!</h1>
          </Col>
        </Row>
      </Container>

      <CurveCarveTop variant="light" />
      <div className="bg-light  py-5">
        <Container className="text-center">
          <Row>
            <Col className="text-center">
              <p>{`Download your ${contentfulGuide.title} by clicking the button below.`}</p>

              <p className="my-4">
                <FaArrowCircleDown size="50px" />
              </p>

              <a
                className="btn btn-secondary btn-lg"
                href={contentfulGuide.download.file.url}
                target="_blank"
                rel="noreferrer"
              >
                Download Guide
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <CurveCarveBottom variant="light" />
    </Layout>
  )
}

export const query = graphql`
  query GuideQuery($id: String) {
    contentfulGuide(id: { eq: $id }) {
      id
      title
      download {
        file {
          url
        }
      }
    }
  }
`

export default GuideDownload
